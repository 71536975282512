import { render, staticRenderFns } from "./BottomNavBar.vue?vue&type=template&id=567af93b&scoped=true&"
import script from "./BottomNavBar.vue?vue&type=script&lang=js&"
export * from "./BottomNavBar.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavBar.vue?vue&type=style&index=0&id=567af93b&prod&lang=scss&"
import style1 from "./BottomNavBar.vue?vue&type=style&index=1&id=567af93b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567af93b",
  null
  
)

export default component.exports